import { Injectable, NgZone, ɵConsole } from '@angular/core'
import { HttpClient } from '@angular/common/http'; 
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { configuracion } from '../config';
import * as jwt_decode from "jwt-decode";

//MODELOS
import { Cliente } from '../models/cliente.model'; 

const base_url = configuracion.base_url;
declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
 
  public cliente: Cliente;

  constructor( private http: HttpClient, 
    private router: Router,  
    private ngZone: NgZone,
    ) {
      this.cliente = new Cliente('','','');
    }

    get token(): string {
        return localStorage.getItem('token') || '';
      } 
    
    get uid():string {
      return this.cliente.uid || '';
    }
    
    public get headers() {
      return {
        headers: {
          'x-token': this.token
        }
      }
    }
      
    public get nombre(){
      return this.cliente.nombre || '';
    }

  guardarLocalStorage( token: string ) { 
    localStorage.setItem('token', token ); 
  }
  
  validarToken() {   
    return this.http.get(`${ base_url }/login/renew-client`, this.headers ).pipe(map(( resp: any ) => { 
      const { email, nombre, uid } = resp.cliente;

      this.cliente.nombre = nombre;
      this.cliente.email = email;
      this.cliente.uid = uid;

      this.guardarLocalStorage( resp.token); //, resp.menu 
      return (true);
    }),
    catchError( (error) => {
      this.router.navigate(['/sign-in']);
      return of(false);
    })); 
  }

  login(formData: any ){  
    return this.http.post(`${ base_url }/login/cliente`, formData )
                .pipe(
                  tap( (resp: any) => { 
                    this.guardarLocalStorage( resp.token); //, resp.menu 
                  })
                );
  }

  guaradarRecordar(email: String, password: String){ 
    let coded = btoa(JSON.stringify({ email: email, password: password, remember: true }));
    localStorage.setItem('sp', coded);
  }
  quitarRecordar(){ 
    localStorage.removeItem('sp');
  }
  leerUsr(){
    let coded = localStorage.getItem('sp');
    if(coded === null){
      return JSON.parse('{"email":"","password":"","remember":false}');
    }
    return JSON.parse(atob(coded)); 
  }
    
  logout() { 
    try{
      localStorage.removeItem('token'); 
    }catch(e){ 
    } 
  }

  getDecodedAccessToken(): Cliente {
    try{
        return jwt_decode( this.token );
    }
    catch(Error){
        return null;
    }
  }
  async estaLogeado(){
    try{ 
      let token = await localStorage.getItem('token'); 
      if(token !== null){ 
        if(token.length > 5){ 
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    } catch(excep){
      return false;
    }
  } 
  crearUsuario_paso1(formData: any){
     
    this.cliente.email = formData.email;
    this.cliente.nombre = formData.nombre;
    this.cliente.telefono = formData.telefono;
    this.cliente.password = formData.password; 
    
    return true;
    
  }
  crearUsuario_paso2(formData: any){
     
    this.cliente.rfc = formData.rfc;
    this.cliente.nombreNegocio = formData.nombreNegocio;
    this.cliente.giroNegocio = formData.giroNegocio; 
   
    return this.crearUsuario();
  }
  crearUsuario(){ 
    return this.http.post(`${ base_url }/clientes`, this.cliente, this.headers )
                .pipe(
                  tap( (resp: any) => {
                    this.guardarLocalStorage( resp.token); //, resp.menu 
                  })
                ); 
  }

  actualizarUsuario(formData: any, idUsuario:string){
    //console.log("ClienteService | actualizarUsuario");
    return this.http.put(`${ base_url }/clientes/${ idUsuario }`, formData, this.headers );
  }
  actualizarPerfil( data: { email: string, nombre: string, role: string } ) {
    //console.log("ClienteService | actualizarPerfil");

    data = {
      ...data, 
    }

    return this.http.put(`${ base_url }/clientes/${ this.uid }`, data, this.headers );

  }
  eliminarUsuario( idUsuario:string){
    //console.log("ClienteService | eliminarUsuario");
    return this.http.delete(`${ base_url }/clientes/${ idUsuario }`,  this.headers );
  }
  
  getUsuarios(){ 
    //console.log("ClienteService | getUsuarios");
    return this.http.get(`${ base_url }/clientes`,  this.headers);
  }
  getMiPerfil(){ 
    //console.log("ClienteService | getMiPerfil");
    return this.http.get(`${ base_url }/clientes/mi-perfil`,  this.headers);
  }
  getDatosUsuario(idUsuario: string){
    //console.log("ClienteService | getDatosUsuario");
    return this.http.get(`${ base_url }/clientes/${ idUsuario }`,  this.headers);
  }
  
}
