import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoPageFoundComponent } from './no-page-found/no-page-found.component';
import { AuthGuard } from '../guards/auth.guard'; 

const routes: Routes = [
  
  {
    path: '',  redirectTo: 'sign-in', pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  }, 
	/*
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  }, 
  {
    path: 'verification',
    loadChildren: () => import('./verification/verification.module').then( m => m.VerificationPageModule)
  },
  {
    path: 'item-detail',
    loadChildren: () => import('./item-detail/item-detail.module').then( m => m.ItemDetailPageModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./reviews/reviews.module').then( m => m.ReviewsPageModule)
  },
  {
    path: 'seller-info',
    loadChildren: () => import('./seller-info/seller-info.module').then( m => m.SellerInfoPageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'title',
    loadChildren: () => import('./title/title.module').then( m => m.TitlePageModule)
  },
  {
    path: 'add-review',
    loadChildren: () => import('./add-review/add-review.module').then( m => m.AddReviewPageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  }, 
  {
    path: 'filter',
    loadChildren: () => import('./filter/filter.module').then( m => m.FilterPageModule)
  },
  */
  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'sign-up-two', 
    loadChildren: () => import('./sign-up-two/sign-up-two.module').then( m => m.SignUpTwoPageModule)
  },
  {
    path: 'home', 
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule) 
  },
  {
    path: 'search',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'item/:idCategoria',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./item/item.module').then( m => m.ItemPageModule)
  },
  {
    path: 'item',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./item/item.module').then( m => m.ItemPageModule)
  },
  {
    path: 'item-detail/:idProducto',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./item-detail/item-detail.module').then( m => m.ItemDetailPageModule)
  },
  {
    path: 'cart',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'select-address',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./select-address/select-address.module').then( m => m.SelectAddressPageModule)
  },
  {
    path: 'payment-mode',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./payment-mode/payment-mode.module').then( m => m.PaymentModePageModule)
  },
  {
    path: 'order-confirm',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./order-confirm/order-confirm.module').then( m => m.OrderConfirmPageModule)
  },
  {
    path: 'my-profile',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'add-address',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./add-address/add-address.module').then( m => m.AddAddressPageModule)
  },
  {
    path: 'add-address-form',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./add-address-form/add-address-form.module').then( m => m.AddAddressFormPageModule)
  }, 
  {
    path: 'edit-address/:idDireccion',
    loadChildren: () => import('./edit-address/edit-address.module').then( m => m.EditAddressPageModule)
  },
  {
    path: 'edit-address-form/:idDireccion',
    loadChildren: () => import('./edit-address-form/edit-address-form.module').then( m => m.EditAddressFormPageModule)
  },
  {
    path: 'list-addresses',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./list-addresses/list-addresses.module').then( m => m.ListAddressesPageModule)
  },
  {
    path: 'my-orders',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./my-orders/my-orders.module').then( m => m.MyOrdersPageModule)
  },
  {
    path: 'search-result/:search',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./search-result/search-result.module').then( m => m.SearchResultPageModule)
  },
  { path: '**', component: NoPageFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
