export class Cliente {
    
    constructor(
        public nombre: string,
        public email: string,
        public telefono: string,
        public password?: string,
        public rfc?: string,
        public nombreNegocio?: string,
        public giroNegocio?: string, 
        public uid?: string,
    ) {} 
}

