import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteService } from '../services/cliente.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
   
  
  rtlSide = "left";
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Inicio',
      url: '/home',
      icon: 'zmdi zmdi-home'
    },
    { 
      title: 'Mi perfil',
      url: '/my-profile',
      icon: 'zmdi zmdi-assignment-account'
    },
    { 
      title: 'Mis direcciones',
      url: '/list-addresses',
      icon: 'zmdi zmdi-pin'
    },
    {
      title: 'Mis ordenes',
      url: '/my-orders',
      icon: 'zmdi zmdi-shopping-cart'
    },
    {
      title: 'Carrito',
      url: '/cart',
      icon: 'zmdi zmdi-mall'
    },
    /*{
      title: 'offers',
      url: '/offers',
      icon: 'zmdi zmdi-label'
    }, 
    {
      title: 'my_wishlist',
      url: '/wishlist',
      icon: 'zmdi zmdi-favorite'
    },
    {
      title: 'about_us',
      url: '/about-us',
      icon: 'zmdi zmdi-assignment'
    }, 
    {
      title: 'help_center',
      url: '/contact-us',
      icon: 'zmdi zmdi-comment-text'
    },
    {
      title: 'languges',
      url: '/language',
      icon: 'zmdi zmdi-globe'
    }, */ 

  ];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar, 
    private route: Router,  
    private clienteService: ClienteService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  getNombre(){
    return this.clienteService.nombre;
  }

  closeSession(){
    this.clienteService.logout();
    this.route.navigate(['/sign-in']);
  }

}
