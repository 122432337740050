export const configuracion = { 
    base_url: 'https://farmlink-api.top-secret.mx/api', //   'https://farmlink-api.top-secret.mx/api' 'http://localhost:3000/api'
    facebookAppID: "3302181806537001",  //FB APP ID  - https://developers.facebook.com/apps/
    googleAuthID: "284995326182-dap9bsdel47cgs2vcnd37uhjvcgskvdj.apps.googleusercontent.com",   //console.developers.google.com
    mapsApi: "AIzaSyAJjliRsGU5wapzlMCx_2TA1mRuSGrYCrk",
    mercadoPago: "TEST-944bd508-291c-4bea-842d-33e1e0fc8af2"
  };
  export const firebaseConfig = {
    apiKey: "AIzaSyBDmJkIvOAs9-z2cgpeSN6FrYZttWBIpxk",
    authDomain: "top-secret-auth.firebaseapp.com",
    databaseURL: "https://top-secret-auth.firebaseio.com",
    projectId: "top-secret-auth",
    storageBucket: "top-secret-auth.appspot.com",
    messagingSenderId: "482812548879",
    appId: "1:482812548879:web:0a17208305f44fef52d07a",
    measurementId: "G-BYBR53PPRM"
  };